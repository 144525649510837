.footer-banner {
    margin-top: 20px;
    padding: 20px 0 30px;
    background-color: color(bg-light);
    overflow: hidden;

    @include bp(md) {
        padding: 0;
        height: 100px;
    }

    &__container {
        @include bp(md) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100px;
            width: 100%;
            max-width: 950px;
            margin: 0 auto;
        }
    }

    &__wrapper {
        @include bp(md) {
            display: flex;
            align-items: center;
        }
    }

    &__money,
    &__triangle-right {
        display: none;

        @include bp(md) {
            display: block;
        }
    }

    &__money {
        margin-right: 70px;
    }

    &__headline {
        font-family: $font-family--primary;
        font-size: rem(17);
        line-height: 25px;
        text-align: center;

        @include bp(md) {
            font-size: rem(23);
            line-height: 33px;
            text-align: left;
        }
    }

    &__phone {
        margin: 10px auto 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: color(green);
        height: 58px;
        width: 220px;
        border-radius: 29px;
        border: 3px solid color(white);
        padding-left: 8px;
        filter: drop-shadow(0 7px 20px rgba(27, 87, 146, 0.149));
        text-decoration: none;

        @include bp(md) {
            margin: 0;
        }

        &__img {
            width: 36px;
            height: 36px;
        }

        /* &__svg {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background-color: color(white);

            svg {
                width: 18px;
                height: 18px;

                path {
                    fill: color(green);
                }
            }
        } */

        &__copy {
            margin: 0 auto;
        }

        &__headline,
        &__number {
            font-family: $font-family--primary;
            font-weight: bold;
            font-size: rem(15);
            line-height: 18px;
            color: color(white);
            text-decoration: none;
            text-align: center;
            padding: 0 30px;

            @include bp(md) {
                text-align: left;
                padding: 0 40px 0 15px;
            }
        }

        &__number {
            font-weight: normal;
            font-size: rem(18);
            line-height: 22px;
            padding: 0 15px;
        }
    }
}
// Root font size
:root {
    font-size: 16px;
}

*,
*::after,
*::before {
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
}

body {
    font-size: 16px;
    font-family: $font-family--primary;
    font-weight: normal;
    line-height: normal;
    background-color: color(white);
    color: color(text);
    overflow-x: hidden;
}

img {
    max-width: 100%;
    height: auto;
}

.underline {
    text-decoration: underline;
}

.hide-desktop {
    @include bp(lg) {
        display: none;
    }
}

.hide-mobile {
    @include bp(max-width 1279px) {
        display: none;
    }
}
// Footer Styles
.footer {
    padding: 60px 0 100px 0;

    &__container {
        width: 100%;
        max-width: 668px;
        margin: 0 auto;

        @include bp(xl) {
            max-width: 1000px;
        }
    }

    &__headline {
        font-family: $font-family--primary;
        font-weight: bold;
        font-size: rem(13);
        line-height: 15px;
        text-align: center;
        margin-bottom: 19px;

        @include bp(sm) {
            margin-bottom: 30px;
        }
    }

    &__disclaimer {
        font-family: $font-family--primary;
        font-weight: normal;
        font-size: rem(14);
        line-height: 23px;
        text-align: center;

        a {
            text-decoration: none;
            color: color(text);
        }
    }

    &__copyright {
        font-family: $font-family--primary;
        font-weight: bold;
        font-size: rem(14);
        line-height: 25px;
        text-align: center;
        margin-top: 19px;

        @include bp(sm) {
            margin-top: 30px;
        }
    }
}
.hero-banner {
    $self: &;
    position: relative;

    // Half Circle
    &::after {
        content: '';
        display: block;
        background-color: color(bg-half-circle);
        position: absolute;
        top: -70px;
        left: 50%;
        width: 120%;
        transform: translateX(-50%);
        height: 200px;
        z-index: -1;
        border-radius: 30%;

        @include bp(sm) {
            top: -90px;
            height: 240px;
            border-radius: 50%;
        }

        @include bp(lg) {
            top: -425px;
            height: 580px;
            width: 1140px;
        }

        @include bp(xl) {
            width: 1320px;
        }
    }

    &.no-phone {
        &::after {
            height: 450px;
            top: -100px;

            @include bp(sm) {
                top: -90px;
                height: 270px;
            }

            @include bp(lg) {
                top: -360px;
                height: 580px;
                width: 1240px;
            }

            @include bp(xl) {
                width: 1450px;
            }
        }
    }

    &__heading {
        font-family: $font-family--primary;
        font-style: normal;
        font-weight: normal;
        font-size: rem(22);
        line-height: 27px;
        text-align: center;
        background-color: color(bg-half-circle);
        color: color(black);
        width: 100%;
        max-width: 450px;
        margin: 22px auto 0;

        @include bp(sm) {
            font-size: rem(29);
            line-height: 37px;
        }

        #{$self}.no-phone & {
            margin: 15px auto 0;
        }
    }

    &__phone {
        margin: 22px auto 0;
        display: flex;
        align-items: center;
        background-color: color(white);
        height: 76px;
        width: 303px;
        border-radius: 4px;
        border: 5px solid color(white);
        filter: drop-shadow(0 10.5263px 13px rgba(12, 34, 64, 0.149));
        text-decoration: none;
        font-family: $font-family--primary;
        font-style: normal;
        font-weight: bold;
        font-size: rem(23);
        line-height: 27px;
        text-align: center;
        color: color(white);

        @include bp(sm) {
            height: 90px;
            font-size: rem(26);
            line-height: 30px;
        }

        &__img {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px 0 0 4px;
            background-color: #24954B;

            img {
                width: 57px;
                height: 66px;

                @include bp(sm) {
                    width: 70px;
                    height: 80px;
                }
            }
        }

        /* &__svg {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: #24954B;
            width: 57px;
            height: 100%;
            border-radius: 4px 0 0 4px;

            svg {
                width: 29px;
                height: 29px;

                path {
                    fill: color(white);
                }
            }
        } */

        &__wrapper {
            background-color: #48AD6B;
            flex: 1;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-radius: 0 4px 4px 0;
            padding-left: 17px;
            text-align: left;
        }

        &__headline {
            font-family: $font-family--primary;
            font-style: normal;
            font-weight: 500;
            font-size: rem(19);
            line-height: 22px;
            color: color(white);
        }

        &__number {
            font-family: $font-family--primary;
            font-style: normal;
            font-weight: bold;
            font-size: rem(23);
            line-height: 27px;
            text-align: center;
            color: color(white);
            text-decoration: none;
            padding-top: 4px;

            @include bp(sm) {
                font-size: rem(26);
                line-height: 30px;
            }
        }
    }
}
// Colors
$colors: (
    blue: #2274CB,
    green: #2D9B53,
    white: #FFFFFF,
    black: #000000,
    red: #FF0000,
    text: #3D3D3D,
    bg-light: #F5F5F5,
    bg-dark: #59626C,
    border: #ECEDEE,
    divider: #99A1AB,
    form-border: #E0E5E8,
    error: #D53839,
    error-light: #FFD9D9,
    bg-half-circle: #ECEDEE,
);

$breakpoints: (
    xxs: 375px,
    xs: 480px,
    sm: 768px,
    md: 1024px,
    lg: 1280px,
    xl: 1440px,
    xxl: 1680px,
    xxxl: 1920px
);

// Font Imports
$font-imports: (
    'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap'
);

// Fonts
$font-family--primary: 'Roboto', sans-serif;

$container: (
    'gutter': (default: 10px, xxs: 20px, sm: 20px),
    'width': (default: 100%, md: 875px, lg: 1140px, xl: 1320px)
);

// Gradients
$gradients: (
);

// Shadow Styles
$shadows: (
    header: '0px 5px 10px rgba(59, 73, 88, 0.15)'
);

// Z-index
$zindex: (
    header: 10,
    sticky-phone: 15
);
/**
 * Screen Readers Styles
 */

/**
  * Only display content to screen readers
  *
  * https: //a11yproject.com/posts/how-to-hide-content/
  * https: //hugogiraudel.com/2016/10/13/css-hide-and-seek/
*/
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

/**
  * Use in conjunction with .sr-only to only display content when it's focused.
  *
  * Useful for "Skip to main content"links; see https: //www.w3.org/TR/WCAG20-TECHS/G1.html
  */
.sr-only-focusable {
    &:active,
    &:focus {
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
        white-space: normal;
    }
}
.form {
    $form: &;
    margin-top: 80px;
    padding-bottom: 40px;

    @include bp(sm) {
        margin-top: 110px;
    }

    .divider + & {
        margin-top: 68px;

        @include bp(sm) {
            margin-top: 73px;
        }
    }

    .hero-banner.no-phone + & {
        margin-top: 55px;
    }

    &.no-tom {
        margin-top: 62px;
        padding-bottom: 60px;

        @include bp(md) {
            margin-top: 48px;
        }
    }

    &__container {
        background-color: color(white);
        border: 3px solid color(form-border);
        border-radius: 10px;
        filter: drop-shadow(0 9px 19px rgba(28, 40, 60, 0.251));
        padding: 0 10px 50px;
        width: 100%;
        max-width: 484px;
        margin: 0 auto;
        position: relative;

        @include bp(sm) {
            filter: none;
        }

        @include bp(md) {
            max-width: 717px;
        }

        #{$form}.no-tom & {
            border: none;
            border-radius: 0;
            filter: none;
            padding-bottom: 0;
        }
    }

    &__quote {
        display: none;

        @include bp(sm) {
            display: block;
            position: absolute;
            top: -100px;
            left: 50%;
            transform: translateX(60px);
        }

        #{$form}.no-tom & {
            @include bp(sm) {
                top: -80px;
                transform: translateX(145px);
            }

            @include bp(md) {
                transform: translateX(200px);
            }

            @include bp(lg) {
                top: -50px;
                transform: translateX(280px);
            }
        }
    }

    &__image {
        text-align: center;
        margin-top: -51px;
    }

    &__heading {
        margin-top: 14px;
        font-family: $font-family--primary;
        font-style: normal;
        font-weight: normal;
        font-size: rem(23);
        line-height: 26px;
        text-align: center;

        @include bp(md) {
            font-size: rem(30);
            line-height: 42px;
        }
    }

    &__form {
        margin-top: 50px;
    }

    &__input-container {
        width: 100%;
        max-width: 280px;
        height: 82px;
        background-color: color(border);
        padding: 8px;
        border-radius: 5px;
        display: block;
        margin: 0 auto;
        position: relative;

        &::before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 25px 23px 25px;
            border-color: transparent transparent color(border) transparent;
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
        }

        &.error {
            background-color: color(error-light);

            &::before {
                border-color: transparent transparent color(error-light) transparent;
            }
        }
    }

    &__input {
        font-family: $font-family--primary;
        font-style: normal;
        font-weight: normal;
        font-size: rem(20);
        line-height: 23px;
        text-align: center;
        background-color: color(white);
        border: 1px solid #A2A2A2;
        border-radius: 5px;
        display: block;
        width: 100%;
        height: 65px;
        margin: 0 auto;
    }

    &__error-message {
        display: none;
        margin-top: 8px;
        color: color(error);
        text-align: center;
    }

    &__submit {
        display: block;
        margin: 18px auto 0;
        width: 100%;
        max-width: 280px;
        height: 65px;
        background-color: color(blue);
        border: 1px solid color(blue);
        filter: drop-shadow(0 3px 2px rgba(13, 38, 51, 0.188));
        border-radius: 3px;
        font-family: $font-family--primary;
        font-style: normal;
        font-weight: 500;
        font-size: rem(21);
        line-height: 24px;
        text-align: center;
        color: color(white);
        padding: 8px 40px;
        position: relative;
        cursor: pointer;

        @include bp(sm) {
            margin-top: 30px;
        }

        &--small {
            height: 60px;

            @include bp(sm) {
                height: 65px;
            }
        }

        &__img {
            position: absolute;
            right: 20px;
            top: calc(50% - 7px);
        }
    }

    &__phone {
        margin: 8px auto 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: color(green);
        width: 100%;
        max-width: 280px;
        height: 60px;
        padding: 0 20px;
        box-shadow: 0 3px 2px rgba(13, 38, 51, 0.188);
        border-radius: 4px;
        text-decoration: none;
        font-family: $font-family--primary;
        font-weight: bold;
        font-size: rem(18);
        line-height: 21px;
        color: color(white);
        padding-left: 14px;

        @include bp(sm) {
            padding: 0 10px;
            height: 65px;
            font-size: rem(21);
            line-height: 25px;
        }

        &__img {
            width: 30px;
            height: 30px;
            margin-right: 14px;

            @include bp(sm) {
                width: 38px;
                height: 38px;
            }
        }

        /* &__svg {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: color(white);

            svg {
                width: 15px;
                height: 15px;

                path {
                    fill: color(green);
                }
            }

            @include bp(sm) {
                width: 38px;
                height: 38px;

                svg {
                    width: 19px;
                    height: 19px;
                }
            }
        }

        &__number {
            font-family: $font-family--primary;
            font-weight: bold;
            font-size: rem(18);
            line-height: 21px;
            color: color(white);
            text-decoration: none;
            padding-left: 14px;

            @include bp(sm) {
                font-size: rem(21);
                line-height: 25px;
            }
        } */
    }
}
.divider {
    margin-top: 17px;

    @include bp(sm) {
        margin-top: 24px;
    }

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__text {
        font-family: $font-family--primary;
        font-weight: normal;
        font-size: rem(16);
        line-height: 19px;
        text-align: center;
        color: color(divider);
        text-transform: uppercase;
        flex: 0 1 100%;
        white-space: nowrap;
        margin: 0 14px;

        @include bp(sm) {
            flex: 0 1 auto;
            margin: 0 23px;
        }
    }

    &__line {
        flex: 0 1 100%;
        height: 1px;
        background-color: color(divider);

        @include bp(sm) {
            flex: 0 1 200px;
        }
    }

    &__heading {
        font-family: $font-family--primary;
        font-style: normal;
        font-weight: normal;
        font-size: rem(19);
        line-height: 27px;
        text-align: center;
        margin: 27px auto 0;
    }
}
// Header Styles
.header {
    background-color: color(white);
    box-shadow: shadow(header);
    position: relative;
    z-index: zindex(header);

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;

        @include bp(sm) {
            height: 70px;
        }
    }

    &__logo {
        img {
            width: 79px;
            height: auto;

            @include bp(sm) {
                width: 116px;
            }
        }
    }

    &__phone {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: color(blue);
        height: 40px;
        border-radius: 20px;
        border: 2px solid color(white);
        filter: drop-shadow(0 5px 10px rgba(59, 73, 88, 0.451));
        text-decoration: none;
        font-family: $font-family--primary;
        font-weight: bold;
        font-size: rem(17);
        line-height: 20px;
        color: color(white);
        padding: 10px 20px 10px 5px;

        &--green {
            background-color: color(green);
        }

        @include bp(sm) {
            height: 44px;
            border-radius: 22px;
            font-size: rem(18);
            line-height: 21px;
        }

        @include bp(xl) {
            height: 46px;
            border-radius: 23px;
            font-size: rem(19);
            line-height: 22px;
        }

        &__img {
            margin-right: 12px;
        }

        /* // Inline SVG styles
        &__svg {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            background-color: color(white);

            svg {
                width: 13px;
                height: 13px;

                path {
                    fill: color(blue);
                }
            }

            @include bp(sm) {
                width: 28px;
                height: 28px;

                svg {
                    width: 14px;
                    height: 14px;
                }
            }

            @include bp(xl) {
                width: 29px;
                height: 29px;

                svg {
                    width: 14.5px;
                    height: 14.5px;
                }
            }
        }

        &__number {
            font-family: $font-family--primary;
            font-weight: bold;
            font-size: rem(17);
            line-height: 20px;
            color: color(white);
            text-decoration: none;
            padding: 10px 15px;

            @include bp(sm) {
                font-size: rem(18);
                line-height: 21px;
            }

            @include bp(xl) {
                font-size: rem(19);
                line-height: 22px;
            }
        } */
    }
}
.sticky-phone {
    width: 100%;
    position: fixed;
    bottom: -70px;
    z-index: zindex(sticky-phone);
    transition: transform 300ms;
    will-change: transform;

    &.show {
        transform: translateY(-90px);
    }

    &__phone {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: color(green);
        height: 58px;
        border-radius: 29px;
        border: 2px solid color(white);
        padding: 0 15px 0 10px;
        filter: drop-shadow(0 5px 10px rgba(59, 73, 88, 0.451));
        text-decoration: none;
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
        font-family: $font-family--primary;
        font-weight: normal;
        font-size: rem(18);
        line-height: 22px;
        color: color(white);
        padding-left: 11px;

        /* &__svg {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background-color: color(white);

            svg {
                width: 18px;
                height: 18px;

                path {
                    fill: color(green);
                }
            }
        }

        &__number {
            font-family: $font-family--primary;
            font-weight: normal;
            font-size: rem(18);
            line-height: 22px;
            color: color(white);
            text-decoration: none;
            padding-left: 11px;
        } */
    }
}